@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.moyasar.com/mpf/1.5.6/moyasar.css");

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
	--red: #ef3f24;
	--secondary: #faa32b;
	--white: #ffffff;
	--whiteGrey: #fafafa;
	--black: #000000;
	--grey: #d9d9d9;
	--lightBlue: #78bac6;
	--darkGrey: #7e7e7e;
}
.html-content {
	font-size: 1rem;
	line-height: 1.5;
}

::before,
::after {
	border: 0px;
}

.html-content * {
	font-size: inherit; /* Ensure all child elements inherit the parent font size */
}

/* Default styles for mobile screens */

p {
	font-size: 10px;
	/* Font size for mobile screens */
}

h1 {
	font-size: 16px;
	/* Font size for mobile screens */
}

/* Styles for tablet screens */
@media (min-width: 768px) {
	p {
		font-size: 12px;
		/* Font size for tablet screens */
	}

	h1 {
		font-size: 20px;
		/* Font size for tablet screens */
	}
}

/* Styles for laptop screens and larger */
@media (min-width: 1024px) {
	p {
		font-size: 10px;
		/* Keep the same for laptop screens */
	}

	h1 {
		font-size: 16px;
		/* Keep the same for laptop screens */
	}
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

body {
	background: #fafafa;
	font-weight: 400 !important;
	background: #fafafa;
	font-weight: 400 !important;
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

@layer base {
	:root {
		--radius: 0.5rem;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	-webkit-appearance: none;
	margin: 0;
}
